.landing-container {
  background-color: #2C3F53;
}

.landing-featured-image {
  height: 400px;
  background-image: url('@/assets/cpu_colored.png');
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem 0 0 0.5rem;
}
.landing-title {
  font-size: 32px;
}
.landing-logo-image {
  height: 150px;
  width: 450px;
  background-image: url('@/assets/dojo_five_logo.png');
  background-size: cover;
  background-position: center;
}
