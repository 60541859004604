pre.code-snippet {
    padding: 20px;
}

pre.code-snippet code {
  counter-reset: line-numbering;
}

pre.code-snippet code span {
  display: block;
}

pre.code-snippet code span:hover {
    color: #FFB020;
}

pre.code-snippet code span::before {
  content: counter(line-numbering);
  counter-increment: line-numbering;
  display: inline-block;
  width: 40px;
}
